import { Assert } from '@aura/core-lib';
import { LftSkeleton, LftTypography } from '@lift/design-system-react-web/dist/components';
import LftLogotipo from '@lift/design-system-react-web/dist/components/LftLogotipo';
import { useStore } from 'effector-react';
import isEqual from 'lodash/isEqual';
import { combineEvents } from 'patronum';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, Navigate, Outlet, useLocation } from 'react-router-dom';
import { codigosCursosAtualizadosEvent } from '../../hooks/codigos-cursos-complementares/codigos-cursos-complementares.hook';
import { useCursos } from '../../hooks/cursos/cursos.hook';
import { useResponsividade } from '../../hooks/responsividade/responsividade.hook';
import { ConfigsManager } from '../../infra/configs/configs.manager';
import { Curso } from '../../models/usuario.model';
import analyticsService, { TiposConteudo } from '../../services/analytics/analytics.service';
import { useCurso } from '../../stores/curso/curso.hook';
import { atualizaCurso, atualizaDisciplinas } from '../../stores/curso/curso.store';
import { $listaCurso } from '../../stores/lista-curso/lista-curso.store';
import { atualizaLoadingCursos } from '../../stores/loading-curso/loading-cursos.store';
import { useUsuario } from '../../stores/usuario/usuario.hook';
import { $credenciais, $isLead, usuarioAtualizado, usuarioMigrado } from '../../stores/usuario/usuario.store';
import { capitalizeFormatter } from '../../utils/formatters/capitalize/capitalize.formatter';
import { converterParaKebabCase } from '../../utils/formatters/converter-string/converter-string';
import { getTipoCurso, transformarTipoCurso } from '../../utils/redirect-home/redirect-home.types';
import { Header } from '../header/header.component';
import { InputSearch } from '../input-search/input-search.component';
import { MenuAcessibilidade } from '../menu-acessibilidade';
import { MenuAvatar } from '../menu-avatar/menu-avatar.component';
import MenuSanduiche from '../menu-sanduiche/menu-sanduiche.component';
import ScreenError from '../screen-error';
import Sidebar from '../sidebar/sidebar.component';
import S from './app-container.styles';
import {
  BaseEvents,
  CursoSelecionado,
  Modalidade,
  ModalidadeRest,
  RegistrationDataEvents,
} from './app-container.types';

const { brand } = ConfigsManager.getInstance();
const DEFAULT_TIPO_CURSO = 'GRAD';

export const AppContainer: React.FC = () => {
  const [renderConteudo, setRenderConteudo] = useState(true);
  const [pathnameReal, setPathnameReal] = useState<string | null>(null);
  const tipoCursoRef = useRef<string | undefined>(undefined);
  const { pathname } = useLocation();
  const { erro: erroUsuario } = useUsuario();
  const { curso } = useCurso();
  const { desktop } = useResponsividade();
  const isLead = useStore($isLead);
  const listaCurso = useStore($listaCurso);
  const { erro: erroCursos, pendente: cursosPendente } = useCursos();
  const idExterno = useStore($credenciais);
  Assert.nonNullable(idExterno);

  const [tagSelecionada, setTagSelecionada] = useState(DEFAULT_TIPO_CURSO);
  const cursoSelecionadoLocal = localStorage.getItem('cursoSelecionado');

  atualizaLoadingCursos(cursosPendente);

  const obterTipoConteudo = (possuiDisciplinas: boolean, possuiCursosComplementares: boolean) => {
    if (possuiDisciplinas) {
      return possuiCursosComplementares ? TiposConteudo.DISCIPLINAS_E_CURSOS_COMPLEMENTARES : TiposConteudo.DISCIPLINAS;
    }
    return possuiCursosComplementares ? TiposConteudo.CURSOS_COMPLEMENTARES : TiposConteudo.NENHUM;
  };

  const registrarNivel = useCallback(
    (nivel: number) => {
      const baseEvents: BaseEvents = {
        usuario: usuarioAtualizado,
        codigosCursos: codigosCursosAtualizadosEvent,
      };

      const events: RegistrationDataEvents =
        isLead && nivel === 2 ? { ...baseEvents, gradeCurricular: atualizaDisciplinas } : baseEvents;

      const $registrationDataEvents = combineEvents({ events });

      const watcher = $registrationDataEvents.watch((payload) => {
        const cursoSelecionadoLocal = localStorage.getItem('cursoSelecionado');
        let cursoSelecionado: CursoSelecionado | undefined;

        if (isLead && cursoSelecionadoLocal) {
          const parsedSession = JSON.parse(cursoSelecionadoLocal);
          cursoSelecionado = {
            nomeCurso: converterParaKebabCase(parsedSession.nome),
            tipoCurso: getTipoCurso[parsedSession.tipo],
            disciplinas: payload?.gradeCurricular?.disciplinas,
          };
        }

        const possuiDisciplinasUsuario = (payload.usuario.curso?.disciplinas?.length ?? 0) > 0;
        const possuiDisciplinasCursoSelecionado = (cursoSelecionado?.disciplinas?.length ?? 0) > 0;
        const possuiDisciplinas = possuiDisciplinasUsuario || possuiDisciplinasCursoSelecionado;
        const possuiCursosComplementares = payload.codigosCursos?.length > 0;
        const idLead = nivel === 2 ? payload.usuario.id : payload?.usuario?.id;

        analyticsService.registrationData({
          inscricao: {
            idLead: isLead ? idLead : undefined,
            idInscricao: idExterno?.atila ?? idExterno?.inscricao,
            idCandidato: idExterno?.candidato,
            tipoCurso: payload?.usuario?.curso?.tipo ?? cursoSelecionado?.tipoCurso,
            curso: payload?.usuario?.curso?.nome ?? cursoSelecionado?.nomeCurso,
            modalidade: payload?.usuario?.curso?.modalidade,
            nomInstituicao: brand,
          },
          matricula: {
            codMatricula: payload?.usuario?.matriculaAcademica?.codigo,
          },
          conteudo: {
            tipoConteudo: obterTipoConteudo(possuiDisciplinas, possuiCursosComplementares),
          },
        });
      });

      return { desinscrever: () => watcher() };
    },
    [idExterno?.atila, idExterno?.candidato, idExterno?.inscricao, isLead],
  );

  useEffect(() => {
    analyticsService.registrationDataN1({
      inscricao: {
        usuarioLead: isLead,
        idInscricao: idExterno.atila ?? idExterno.inscricao,
        idCandidato: idExterno.candidato,
      },
    });

    const watcherNivel2 = registrarNivel(2);
    const watcherNivel3 = registrarNivel(3);

    if (isLead && cursoSelecionadoLocal) {
      setTagSelecionada(JSON.parse(cursoSelecionadoLocal).tipo || DEFAULT_TIPO_CURSO);
    }

    return () => {
      watcherNivel2.desinscrever();
      watcherNivel3.desinscrever();
    };
  }, [idExterno, isLead, registrarNivel, cursoSelecionadoLocal]);

  useEffect(() => {
    const subscription$ = usuarioMigrado.watch(() => setRenderConteudo(false));
    return () => subscription$.unsubscribe();
  }, []);

  const selecionarCurso = (item: Curso) => {
    if (isLead && item && !isEqual(item, JSON.parse(cursoSelecionadoLocal!))) {
      localStorage.setItem('cursoSelecionado', JSON.stringify(item));
      atualizaCurso(item);
    }
  };

  const tiposCompletos = {
    [Modalidade.GRAD]: { label: 'Graduação', value: 'GRAD' },
    [Modalidade.POS]: { label: 'Pós-Graduação', value: 'POS' },
    [Modalidade.TEC]: { label: 'Técnico', value: 'TEC' },
  };

  const tiposUnicos = Array.isArray(listaCurso)
    ? Object.values(Modalidade).filter((tipo) => listaCurso.some((curso) => curso.tipo === tipo))
    : [];

  const tagPropsMapeadas = tiposUnicos.map((tipo, index) => ({
    id: `Tag${index + 1}`,
    label: tiposCompletos[tipo]?.label,
    value: tiposCompletos[tipo]?.value,
    name: 'type-select',
  }));

  const capitalizeListaCurso = (titulo: string): string => {
    const preposicoes = new Set(['de', 'da', 'do', 'das', 'dos', 'em', 'por', 'a', 'e']);
    return titulo
      .toLowerCase()
      .split(' ')
      .map((palavra, index) =>
        index === 0 || !preposicoes.has(palavra) ? palavra.charAt(0).toUpperCase() + palavra.slice(1) : palavra,
      )
      .join(' ');
  };

  useEffect(() => {
    if (pathname !== '/') {
      setPathnameReal(pathname);
    }
  }, [pathname]);

  const pushPageLoadedDatalayer = useCallback((pathname: string, tipoCursoRef: string | undefined) => {
    analyticsService.pageLoaded({ path: pathname, brand, type: tipoCursoRef });
  }, []);

  const atualizaTipoCursoCandidato = useCallback(
    (pathnameReal: string) => {
      if (tipoCursoRef.current) {
        return pushPageLoadedDatalayer(pathnameReal, tipoCursoRef.current);
      }
      const unsubscribe = usuarioAtualizado.watch((usuario) => {
        const tipoCurso = usuario?.curso?.tipo ? transformarTipoCurso[usuario.curso.tipo] : undefined;
        tipoCursoRef.current = tipoCurso ? getTipoCurso[tipoCurso] : undefined;
        pushPageLoadedDatalayer(pathnameReal, tipoCursoRef.current);
      });
      return { unsubscribe };
    },
    [pushPageLoadedDatalayer],
  );

  useEffect(() => {
    if (pathnameReal) {
      if (isLead) {
        tipoCursoRef.current = curso?.tipo ? getTipoCurso[curso.tipo] : undefined;
        return pushPageLoadedDatalayer(pathnameReal, tipoCursoRef.current);
      }
      atualizaTipoCursoCandidato(pathnameReal);
    }
  }, [atualizaTipoCursoCandidato, curso?.tipo, isLead, pathnameReal, pushPageLoadedDatalayer]);

  const listaCursoFiltrada = () => {
    const modalidadeOrdem = [
      ModalidadeRest.TOTAL_EAD_REST,
      ModalidadeRest.SEMIPRESENCIAL_REST,
      ModalidadeRest.PRESENCIAL_REST,
      ModalidadeRest.AO_VIVO_REST,
      ModalidadeRest.FLEX_REST,
      ModalidadeRest.TELEPRESENCIAL_REST,
    ];

    const listaFiltrada = listaCurso?.filter((curso) => curso.tipo === tagSelecionada);
    const listaCapitalizada = listaFiltrada.map((lista) => ({
      ...lista,
      nome: capitalizeListaCurso(lista.nome),
    }));

    for (const modalidade of modalidadeOrdem) {
      const cursosFiltrados = listaCapitalizada.filter((curso) => curso.modalidade === modalidade);
      if (cursosFiltrados.length > 0) {
        return cursosFiltrados.sort((a, b) => a.nome.localeCompare(b.nome));
      }
    }
    return listaCapitalizada;
  };

  if (erroUsuario && erroUsuario.name !== 'NoContentException') {
    return <ScreenError.Fatal erro="ERRO_CARREGAR_DADOS_USUARIO" />;
  }

  if (erroCursos) {
    return <ScreenError.Fatal erro="ERRO_CARREGAR_CURSOS" />;
  }

  if (renderConteudo) {
    return (
      <S.AppContainer>
        {desktop && (
          <S.SidebarContainer data-testid="sidebar">
            <Sidebar />
          </S.SidebarContainer>
        )}
        <S.MainContainer>
          <Header
            logo={
              <Link to="/">
                <LftLogotipo alt="Logotipo da marca" />
              </Link>
            }
            center={
              <>
                {isLead && pathname === '/disciplinas' && (
                  <>
                    {listaCurso?.length ? (
                      <InputSearch<Curso>
                        tag={{
                          tagPropsMapeadas: tagPropsMapeadas,
                          tagSelecionada: tagSelecionada,
                          setTagSelecionada: setTagSelecionada,
                        }}
                        inputSearch={{
                          placeholder: 'Buscar curso',
                          className: 'listaCursos',
                          dataElement: 'selecionar-curso',
                          dataSection: 'section_busca-cursos',
                        }}
                        listaMenu={{
                          itensFiltrados: listaCursoFiltrada(),
                          atualizaItem: (item) => selecionarCurso(item),
                        }}
                      />
                    ) : (
                      <S.SkeletonContainer className="skeleton-container">
                        <LftSkeleton shape="square" id="skeleton-lista-curso" height="3.5rem" />
                      </S.SkeletonContainer>
                    )}
                  </>
                )}
                {!isLead && desktop && (
                  <S.CursoWrapper>
                    {curso ? (
                      <>
                        <LftTypography component="caption">Curso</LftTypography>
                        <LftTypography component="paragraph" fontWeight="medium">
                          {capitalizeFormatter(curso.nome)}
                        </LftTypography>
                      </>
                    ) : (
                      <LftSkeleton shape="line" id="skeleton-nome-curso" />
                    )}
                  </S.CursoWrapper>
                )}
              </>
            }
            aside={
              desktop ? (
                <S.HeaderAsideContainer>
                  <MenuAcessibilidade dropdownPosition="bottom" />
                  <MenuAvatar />
                </S.HeaderAsideContainer>
              ) : (
                <MenuSanduiche />
              )
            }
          />
          <Outlet />
        </S.MainContainer>
      </S.AppContainer>
    );
  }

  return <Navigate to="/disciplinas" />;
};
